import React,{Component} from 'react';
import {Grid, Cell} from 'react-mdl';

class Experience extends Component{
    render(){
        return(
            <Grid>
                <Cell col={4}>{this.props.startYear}-{this.props.endYear}</Cell>
                <Cell col={8}>
                    <h4 style={{marginTop:'0px', align: 'left'}}>{this.props.jobName}</h4>
                    <h6 style={{font:'italics'}}>{this.props.employer}</h6>
                    <p>{this.props.jobDescription}</p>
                </Cell>
            </Grid>
        )
    }
}

export default Experience;